.toggle-switch {
    position: relative;
    width: 75px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;
    align-content: center;
  }
  .toggle-switch-checkbox {
    display: none;
  }
  .toggle-switch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #ccc;
    border-radius: 20px;
    margin: 0;
  }
  .toggle-switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.2s ease-in 0s;
  }
  .toggle-switch-inner::before, .toggle-switch-inner::after {
    display: block;
    float: left;
    width: 50%;
    height: 30px;
    padding: 0;
    line-height: 30px;
    color: #36ba8c;
    font-weight: bold;
    box-sizing: border-box;
  }
  .toggle-switch-inner:before {
    content: "Yes";
    text-transform: uppercase;
    padding-left: 10px;
    background-color: #36ba8c;
    color: white;
  }
  .toggle-switch-disabled {
    background-color: #36ba8c;
    cursor: not-allowed;
  }
  .toggle-switch-disabled::before {
    background-color: #36ba8c;
    cursor: not-allowed;
  }
  .toggle-switch-inner::after {
    content: "No";
    text-transform: uppercase;
    padding-right: 10px;
    background-color: #ccc;
    color: #fff;
    text-align: right;
  }
  .toggle-switch-switch {
    display: block;
    width: 20px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  .toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
    margin-left: 0;
  }
  .toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
    right: 0px;
  }

  .toggle-switch-inner::before {
    content: attr(data-yes);
  }
  .toggle-switch-inner::after {
    content: attr(data-no);
  }

  .toggle-switch.small-switch {
    width: 40px;
  }
  .toggle-switch.small-switch .toggle-switch-inner:after, .toggle-switch.small-switch .toggle-switch-inner:before {
    content: "";
    height: 20px;
    line-height: 20px;
  }
  .toggle-switch.small-switch .toggle-switch-switch {
    width: 16px;
    right: 20px;
    margin: 2px;
  }
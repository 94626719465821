.material-icons.alert { 
    font-size: 35px;
    color: #FF1205;
    display: inline-block;
}

.material-icons.completed {
    display: inline-block;
    font-size: 35px;
}

.material-icons.reg { 
    font-size: 24px; 
    display: inline-block;
}